import { Analytics } from "@vercel/analytics/react";
import type { AppProps } from "next/app";
import "../styles/globals.css";
import { app } from "../initFirebase";
import i18next, { t } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { useEffect, useState } from "react";
import Header from "../components/Core/Header";
import Footer from "../components/Core/Footer";
import Head from "next/head";
import { Client } from "appwrite";
import { ThemeProvider } from "next-themes";
import { Sparkles } from "lucide-react";
import { useRouter } from "next/router";
import Ads from "../components/Core/Ads";

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

app;
function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  i18next.use(LanguageDetector).init({
    fallbackLng: "en",
    resources: {
      de: {
        translation: require("../public/locales/de/translation.json"),
      },
      en: {
        translation: require("../public/locales/en/translation.json"),
      },
      es: {
        translation: require("../public/locales/es/translation.json"),
      },
      fr: {
        translation: require("../public/locales/fr/translation.json"),
      },
      it: {
        translation: require("../public/locales/it/translation.json"),
      },
      pt: {
        translation: require("../public/locales/pt/translation.json"),
      },
      tr: {
        translation: require("../public/locales/tr/translation.json"),
      },
    },
  });

  const [initialRenderComplete, setInitialRenderComplete] = useState<boolean>(false);

  useEffect(() => {
    setInitialRenderComplete(true);
  }, []);

  useEffect(() => {
    i18next.changeLanguage(router.locale || "en");
  }, [router.locale]);

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.error('AdSense error:', err);
    }
  }, []);

  if (!initialRenderComplete) return <></>;

  const client = new Client();

  client
    .setEndpoint("https://pitm.deploy.emreisik.dev/v1")
    .setProject("66af5d960035099a03ef");

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="light"
      disableTransitionOnChange
    >
      <Head>
        <title>
          Professionalize It To Me - O3-mini & Claude & AI Professional Message Generator
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>
      <Header />
      <main>
        <Component {...pageProps} />
        <Ads />
      </main>
      {process.env.NODE_ENV === 'production' && <Analytics />}
      <Footer />
    </ThemeProvider>
  );
}

export default MyApp;
